<template>
  <div class="notifications">
    <transition name="fade" mode="out-in">
      <base-loader v-if="loading" class="notifications__loader" />
      <card-block v-else>
        <base-table :columns="tableHead" :list="paginList" class="notifications__table">
          <base-table-row
            v-for="item in paginList"
            :key="item.id"
            :columns="tableHead"
            :item="item"
            class="standart-text"
          >
            <template #date>
              <span class="note-color">
                {{ $d(item.date, 'long') }}
              </span>
            </template>
            <template #title>
              <div
                class="notifications__title"
                :class="{ unreaded: item.readed === 'off' }"
                @click="viewDetail(item)"
              >
                {{
                  item.subject.includes('popup_')
                    ? item.subject.replace('popup_', '')
                    : item.subject
                }}
              </div>
            </template>
            <template #type>
              <span class="note-color">
                {{ $t(`type.${item.noticegroup}`) }}
              </span>
            </template>
          </base-table-row>
        </base-table>
        <NewPagination
          :step="pagin.step"
          :start="pagin.start || 0"
          :count="list.length"
          type="base"
          @set-step="setStep"
          @new-pagination="changePagination"
        />
      </card-block>
    </transition>
  </div>
</template>

<script>
import CardBlock from '../components/CardBlock.vue';
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import NewPagination from '@/components/Pagination/NewPagination';
import NotificationSettings from '../components/NotificationSettings.vue';
import mixin from '../../mixins/index';
import viewNotificationDetail from '../../mixins/viewNotificationDetail';
import askUnsavedPageLeave from '@/mixins/askUnsavedPageLeave';
import { isEqual, isEmpty } from 'lodash';
import pagination from '@/mixins/pagination';
import Vue from 'vue';
export default {
  name: 'Notifications',
  components: {
    CardBlock,
    BaseTable,
    BaseTableRow,
    NewPagination,
  },
  mixins: [mixin, askUnsavedPageLeave, pagination, viewNotificationDetail],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'date',
          style: {
            width: '140px',
            verticalAlign: 'top',
          },
        },
        {
          key: 'title',
          style: {
            width: 'calc(100% - 420px)',
            minWidth: '200px',
            verticalAlign: 'top',
          },
        },
        {
          key: 'type',
          style: {
            width: '210px',
            verticalAlign: 'top',
          },
        },
      ],
      formData: {},
      initialBackupData: {},
      formLoading: false,
      settingsModal: null,
      fetchingTimer: null,
    };
  },
  computed: {
    noticeList() {
      const list = this.profile.noticeList.map(element => {
        return {
          ...element,
          list: element.list.filter(subElement => subElement.key === 'ntemail'),
        };
      });
      return list || [];
    },
    list() {
      return this.$store.state.moduleNotifications.list;
    },
    hasChangesSettings() {
      return (
        !isEmpty(this.formData) &&
        !isEmpty(this.initialBackupData) &&
        !isEqual(this.formData, this.initialBackupData)
      );
    },
  },
  watch: {
    hasChangesSettings(val) {
      if (this.settingsModal) {
        Vue.set(this.settingsModal.footer.confirm.props, 'disabled', !val);
      }
    },
    formLoading(val) {
      if (this.settingsModal) {
        Vue.set(this.settingsModal.footer.confirm.props, 'loading', val);
      }
    },
  },
  mounted() {
    this.cycleFetching();
  },
  beforeDestroy() {
    if (this.fetchingTimer) clearTimeout(this.fetchingTimer);
  },
  beforeRouteLeave(_to, _from, next) {
    if (!isEqual(this.formData, this.initialBackupData)) {
      this.askUnsavedPageLeave()
        .then(() => next())
        .catch(() => {})
        .finally(() => this.$modals.close());
    } else next();
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleNotifications/fetchList');
    },
    cycleFetching() {
      this.fetchList();
      this.fetchingTimer = setTimeout(this.fetchList, 60000);
    },
    onChange(item, value) {
      this.formData[item.name] = value;
    },
    showSettings() {
      this.$modals.open({
        name: 'SettingsModal',
        title: this.$t('settingsFormTitle'),
        size: 'small',
        component: NotificationSettings,
        props: { list: this.noticeList },
        onOpen: inst => (this.settingsModal = inst),
        onClose: () => (this.settingsModal = null),
        on: {
          ready: data => {
            this.formData = { ...data };
            this.initialBackupData = { ...data };
          },
          change: data => (this.formData = { ...data }),
        },
        footer: {
          confirm: {
            props: { title: this.$t('save'), disabled: true, loading: false },
            on: { click: () => this.submit() },
          },
          cancel: {
            on: {
              click: () => {
                this.$modals.close();
                this.formData = {};
                this.initialBackupData = {};
              },
            },
          },
        },
      });
    },
    submit() {
      const data = this.getPreparedFormData(this.formData);
      this.formLoading = true;
      let res = 'fail';
      this.updateProfileInfo(data, 'mediator')
        .then(() => (res = 'success'))
        .finally(() => {
          setTimeout(() => {
            this.formLoading = false;
            this.showResultModal(this.$t(`res.${res}`));
            if (this.settingsModal) this.$modals.close({ name: this.settingsModal.name });
          }, 500);
        });
    },
    showResultModal(text) {
      this.$modals.open({
        name: 'Result',
        text,
        size: 'small',
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('ok') },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "settings": "Настроить уведомления",
      "settingsFormTitle": "Настройка уведомлений",
      "save": "Сохранить",
      "list": {
        "title": "Уведомления"
      },
      "type": {
        "finance_notice": "Финансовые уведомления",
        "news_notice": "Новостные уведомления",
        "service_notice": "Уведомления по услугам",
        "support_notice": "От службы поддержки"
      },
      "res": {
        "success": "Настройки успешно сохранены",
        "fail": "К сожалению, что-то пошло не так. Попобуйте позднее"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.notifications {
  &__title {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: $primary-color;
    }

    &.unreaded {
      color: $primary-color;
      font-weight: $font-weight-medium;

      &:hover {
        color: $color-blue.medium;
      }
    }
  }
  &__card {
    max-width: 640px;
  }

  &__table >>> .base-table {
    +breakpoint(sm-and-up) {
      table-layout: fixed;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
